@keyframes AiChatBounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(16px);
    }
    60% {
        transform: translateX(8px);
    }
}
@keyframes AiChatDots {
    50% {
        fill: transparent;
    }
}
.AiChat {
    .AiChatButton {
        position: fixed;
        margin: 0;
        padding: 8px;
        top: 12px;
        right: 20px;
        border: none;
        border-radius: 100%;
        cursor: pointer;
        animation: AiChatBounce 2s ease infinite;
        z-index: 1;

        svg {
            padding-top: 4px;
            width: 32px;
            height: 32px;
        }
    }
    .AiChatWindow {
        position: fixed;
        top: 56px;
        right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 400px;
        min-height: 600px;
        width: 20%;
        height: 60%;
        background: rgba(54, 54, 54, 0.9);
        border: 1px solid #363636;
        border-radius: 8px;
        z-index: 1;

        &.AiChatFullScreen {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 0;
            z-index: 9999;
        }
        .AiChatWindowHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid #363636;

            button:first-child svg path {
                fill: none;
            }
            p {
                color: #FFFFFF;
                font-size: 16px;
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                border: none;
                background: transparent;
                cursor: pointer;

                svg path {
                    fill: #FFFFFF;
                }
            }
        }
        .AiChatWindowBody {
            padding: 0 16px;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            overflow: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }
            &::-webkit-scrollbar-track {
                background: #151515;
            }
            &::-webkit-scrollbar-thumb {
                background: #FFFFFF;
            }
            .AiChatWindowBodyMessage {
                margin: 16px 0;
                padding: 16px;
                border-radius: 16px;
                width: fit-content;
                color: #FFFFFF;
                font-size: 14px;
                white-space: pre-wrap;

                &.AiChatWindowBodyMessageBot {
                    background: rgba(0, 0, 0, 0.3);
                }
                &.AiChatWindowBodyMessageUser {
                    margin-left: auto;
                    background: white;
                    color: #000000;
                }
                svg {
                    width: 40px;
                    height: 10px;

                    circle {
                        fill: #FFFFFF;
                        animation: 1s AiChatDots infinite;
                    }
                    circle:nth-child(2) {
                        animation-delay: 250ms;
                    }
                    circle:nth-child(3) {
                        animation-delay: 500ms;
                    }
                }
            }
        }
        .AiChatWindowFooter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-top: 1px solid #363636;

            input {
                margin-right: 16px;
                width: 100%;
                background: transparent;
                border: none;
                outline: none;
                color: #FFFFFF;
                font-size: 14px;

                &::placeholder {
                    color: #888;
                    font-size: 14px;
                }
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                min-width: 26px;
                min-height: 26px;
                background: #FFFFFF;
                border: none;
                border-radius: 100%;

                svg path {
                    fill: #151515;
                }
            }
        }
        @media screen and (max-width: 600px) {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            min-height: 100%;
            height: 100%;
            border: none;
            border-radius: 0;
            z-index: 9999;

            .AiChatWindowHeader button:first-child {
                display: none;
            }
        }
    }
}