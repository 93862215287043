.house-info-buttons {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    button {
        margin-right: 8px;
        padding: 8px 16px;
        background: transparent;
        border: 1px solid #FFFFFF;
        border-radius: 100px;
        color: #FFFFFF;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:last-child {
            margin-right: 0;
        }
        &.active, &:hover {
            background: #FFFFFF;
            color: #000000;
        }
    }
    @media screen and (max-width: 768px), screen and (max-height: 500px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 16px;

        button {
            margin: 0 0 12px 0;
            width: 45%;
        }
    }
}