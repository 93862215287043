.tour.blocked .menu .houses {
    pointer-events: none;
}
.houses {
    .house {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 12px;
        background: rgba(0, 0, 0, 0.9);
        border: 1px solid #C0C0C0;
        border-right: 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:last-child {
            border-right: 1px solid #C0C0C0;
        }
        &.sold {
            background: rgba(0, 0, 0, 0.3);
            cursor: default;

            p, span {
                color: #C3C3C3;
            }
        }
        &:hover, &.active {
            background: #FFFFFF;

            p, span {
                color: #000000;
            }
        }
        p {
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            transition: all 0.3s ease-in-out;

            b {
                font-size: 12px;
                font-weight: 700;
            }
        }
        span {
            color: #FFFFFF;
            font-size: 10px;
            font-weight: 500;
            line-height: 10px;
            transition: all 0.3s ease-in-out;
        }
    }
    @media screen and (max-width: 768px), screen and (max-height: 500px) {
        width: 100%;

        .house {
            padding: 8px 2px;
            width: 100%;

            span {
                font-size: 9px;
            }
            p b {
                display: none;
            }
        }
    }
}