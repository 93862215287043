.houseInfo {
    position: fixed;
    bottom: 24px;
    left: 24px;
    display: flex;
    background: #00000033;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #00000080;
    }
    & > .house-info-buttons {
        display: none;
    }
    .left .size div,
    .right .top div,
    .right .bottom div {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 16px;

        &:last-child {
            margin-right: 0;
        }
        span {
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
        b {
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;

            sup {
                font-size: 10px;
                font-weight: 700;
            }
        }
    }
    .left {
        padding: 8px 16px;
        min-width: 450px;

        .title {
            display: flex;
            align-items: flex-end;

            h2 {
                margin-right: 16px;
                color: #FFFFFF;
                font-size: 40px;
                font-weight: 600;
                line-height: 40px;
            }
            p {
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
            }
            b {
                align-self: center;
                margin-left: auto;
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                text-transform: uppercase;
            }
            .arrow {
                display: none;
                align-self: center;
                margin-left: auto;
                color: #FFFFFF;
                font-size: 16px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .size {
            display: flex;
            align-items: center;
            margin-top: 16px;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        border-left: 1px solid #FFFFFF;

        .top {
            display: flex;
            align-items: end;
            padding-bottom: 16px;
            border-bottom: 1px solid #FFFFFF;

            div {
                margin-bottom: 0;
            }
        }
        .bottom {
            display: flex;
            align-items: start;
            padding-top: 16px;
            border-top: 1px solid transparent;

            div {
                margin-bottom: 0;
            }
        }
    }
    @media screen and (max-width: 768px), screen and (max-height: 500px) {
        bottom: 0;
        left: 0;
        flex-direction: column;
        width: 100%;
        background: #00000080;
        border-radius: 24px 24px 0 0;

        .left .size, .right {
            max-height: 200px;
            transition: max-height 1s ease, padding 1s 0.1s ease;
            overflow: hidden;
        }
        &.collapsed {
            .left .size, .right {
                max-height: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .left {
            min-width: 100%;

            .title .arrow {
                display: flex;
            }
        }
        .left .house-info-buttons {
            display: none;
        }
        .right {
            border-left: 0;
        }
        .house-info-buttons {
            display: flex;
        }
    }
    @media screen and (max-height: 500px) {
        margin-left: 15vw;
        width: 70vw;

        .left .size, .right {
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
        .house-info-buttons {
            max-height: 100px;
            transition: max-height 1s ease, padding 1s 0.1s ease;
            overflow: hidden;
        }
        &.collapsed {
            .house-info-buttons {
                max-height: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}