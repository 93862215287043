.tour.blocked .control {
    pointer-events: none;
}
.control {
    position: fixed;
    bottom: -3px;
    right: 15px;
    width: 334px;
    height: 334px;

    &:hover .pulse {
        animation: none;
    }
    .pulse {
        position: absolute;
        top: 83px;
        left: 77px;
        width: 181px;
        height: 181px;
        border-radius: 100%;
        animation: pulse 2s infinite;

        @-webkit-keyframes pulse {
            0% {
                -webkit-box-shadow: 0 0 0 0 rgba(196, 196, 196, 0.4);
            }
            70% {
                -webkit-box-shadow: 0 0 0 30px rgba(196, 196, 196, 0);
            }
            100% {
                -webkit-box-shadow: 0 0 0 0 rgba(196, 196, 196, 0);
            }
        }
        @keyframes pulse {
            0% {
                -moz-box-shadow: 0 0 0 0 rgba(196, 196, 196, 0.4);
                box-shadow: 0 0 0 0 rgba(196, 196, 196, 0.4);
            }
            70% {
                -moz-box-shadow: 0 0 0 30px rgba(196, 196, 196, 0);
                box-shadow: 0 0 0 30px rgba(196, 196, 196, 0);
            }
            100% {
                -moz-box-shadow: 0 0 0 0 rgba(196, 196, 196, 0);
                box-shadow: 0 0 0 0 rgba(196, 196, 196, 0);
            }
        }
    }
    .up, .left, .right, .down {
        position: absolute;
        cursor: pointer;
        z-index: 1;

        &.disabled {
            cursor: default;
        }
    }
    .up {
        top: 105px;
        left: 145px;
        width: 43px;
        height: 50px;
        border-radius: 12px 12px 25px 25px;

        &.disabled + div + div + div + .lf-player-container [clip-path="url(#__lottie_element_10)"] {
            opacity: 0.5;
        }
    }
    .left {
        top: 154px;
        left: 101px;
        width: 48px;
        height: 40px;
        border-radius: 12px 25px 25px 12px;

        &.disabled + div + div + .lf-player-container [clip-path="url(#__lottie_element_18)"] {
            opacity: 0.5;
        }
    }
    .right {
        top: 153px;
        left: 186px;
        width: 48px;
        height: 40px;
        border-radius: 25px 12px 12px 25px;

        &.disabled + div + .lf-player-container [clip-path="url(#__lottie_element_14)"] {
            opacity: 0.5;
        }
    }
    .down {
        top: 191px;
        left: 147px;
        width: 41px;
        height: 48px;
        border-radius: 25px 25px 12px 12px;

        &.disabled + .lf-player-container [clip-path="url(#__lottie_element_22)"] {
            opacity: 0.5;
        }
    }
}
@media screen and (max-width: 1024px), screen and (max-height: 500px) {
    .control {
        display: none !important;
    }
}