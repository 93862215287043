.tour {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .panel {
        position: fixed;
        display: none;
        pointer-events: none;
        width: 300px;
        transition: opacity 1s ease-in-out;

        &.city {
            display: block;
            top: 40px;
            right: 10vw;
        }
        @media screen and (max-width: 768px), screen and (max-height: 500px) {
            display: none !important;
        }
    }
    &.blocked .menu .summary .submenu div {
        pointer-events: none;
    }
    video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .menu {
        padding: 0 24px;
        margin-top: 76px;

        @media screen and (max-width: 768px), screen and (max-height: 500px) {
            position: fixed;
            top: 0;
            padding: 0;
            width: 100%;
        }
        .summary {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            flex-wrap: wrap;

            @media screen and (max-width: 768px), screen and (max-height: 500px) {
                margin-bottom: 0;
            }
            .submenu {
                display: flex;
                align-items: center;
                margin-right: 8px;
                padding: 4px;
                border: 1px solid #C0C0C0;

                &.round {
                    border-radius: 200px;

                    @media screen and (max-width: 1080px) {
                        margin-bottom: 16px;
                        margin-right: calc(100% - 300px);
                    }
                    div:hover, div.active {
                        border-radius: 200px;
                    }
                }
                div {
                    margin-right: 4px;
                    padding: 2px 16px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 20px;
                    border-bottom: 0;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover, &.active {
                        background: #FFFFFF;
                        color: #000000;
                    }
                }
                @media screen and (max-width: 768px), screen and (max-height: 500px) {
                    flex-direction: column;
                    margin: 0;
                    padding: 0;

                    div {
                        margin: 0;
                        padding: 8px 2px;
                        min-width: 40px;
                        width: 100%;
                        text-align: center;
                        background: rgba(0, 0, 0, 0.9);
                        border-bottom: 1px solid #C0C0C0;

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                    &.submenu-mode {
                        margin: 0 24px 16px 24px;
                        flex-direction: row;

                        div {
                            margin-right: 4px;
                            padding: 2px 16px;
                            width: auto;
                            background: transparent;
                            border-bottom: 0;

                            &:last-child {
                                margin-right: 0;
                            }
                            &:hover, &.active {
                                background: #FFFFFF;
                                color: #000000;
                            }
                        }
                    }
                    &.submenu-direction, &.submenu-time {
                        position: fixed;
                        top: 180px;
                        left: 0;
                    }
                    &.submenu-level {
                        position: fixed;
                        top: 180px;
                        right: 0;
                    }
                }
            }
        }
        .houses {
            display: flex;
            align-items: center;
        }
    }
}