.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background: rgba(21, 21, 21, 0.6);
    border-top: 1px solid #363636;

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 32px 16px;
        width: 100%;
        max-width: 1200px;

        @media screen and (max-width: 1024px) {
            p {
                max-width: 420px;
                font-size: 12px;
            }
            .buttons button {
                padding: 16px;
                font-size: 10px;
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;

            p {
                margin-right: 0;
                margin-bottom: 16px;
                max-width: none;
            }
        }
        p {
            margin-right: 16px;
            max-width: 550px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 20px;
        }
        .buttons {
            display: flex;

            button {
                padding: 16px 32px;
                border: 1px solid #FFFFFF;
                border-radius: 24px;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-transform: uppercase;
                transition: all .3s ease-in-out;
                cursor: pointer;

                &.white {
                    color: #151515;
                    background: #FFFFFF;

                    &:hover {
                        color: #FFFFFF;
                        background: transparent;
                    }
                }
                &.transparent {
                    color: #FFFFFF;
                    background: transparent;

                    &:hover {
                        color: #151515;
                        background: #FFFFFF;
                    }
                }
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}