.header {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    width: 100%;
    background: transparent;
    z-index: 1;

    a {
        height: 32px;

        div {
            margin-left: -18px;
            margin-top: -74px;
            width: 180px;
            pointer-events: none;
        }
    }
    select {
        margin-right: 52px;
        background: transparent;
        border: none;
        color: #FFFFFF;
        font-size: 13px;
        font-weight: 600;
        line-height: 32px;
        outline: none;
        cursor: pointer;

        option {
            color: #000000;
            font-size: 13px;
            font-weight: 600;
        }
    }
}