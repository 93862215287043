#loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: white;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    z-index: 10;

    svg {
        width: 100%;
        transform: none !important;
    }
    &.opacity {
        opacity: 0;
    }
    &.hidden {
        display: none;
    }
}